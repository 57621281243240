export const ExperienceData = [
  {
    id: 1,
    company: "Maulana Abul Kalam Azad University of Technology",
    location: "Kolkata, West Bengal, India",
    isWorkplace: false,
    designation: "B.Tech - CSE",
    dateJoining: "Aug 2013",
    dateEnd: "Jul 2017",
    skills: [
      "Algorithms",
      "Database Technology",
      "Computer Networks",
      "NData Structures",
      "Introduction to Programing",
    ],
    companyLogo:
      "https://images.credly.com/size/340x340/images/ae2f5bae-b110-4ea1-8e26-77cf5f76c81e/GCC_badge_IT_Support_1000x1000.png",
  },
  {
    id: 2,
    company: "Vellore Institute of Technology, Vellore",
    location: "Vellore, Tamil Nadu, India",
    isWorkplace: false,
    designation: "M.Tech - CSE",
    dateJoining: "Jul 2018",
    dateEnd: "Jun 2020",
    skills: [
      "Algorithms",
      "Database Technology",
      "Computer Networks",
      "Engineering Maths",
      "Machine Learning",
      "Web Services",
      "Software Engineering",
    ],
    companyLogo:
      "https://images.credly.com/size/340x340/images/ae2f5bae-b110-4ea1-8e26-77cf5f76c81e/GCC_badge_IT_Support_1000x1000.png",
  },
  {
    id: 3,
    company: "L&T Technology Services",
    location: "Mysuru, Karnataka, India",
    isWorkplace: true,
    designation: "Engineering Intern",
    dateJoining: "Sep 2020",
    dateEnd: "Dec 2020",
    skills: [
      "Docker Products",
      "HTML",
      "Cascading Style Sheets (CSS)",
      "JavaScript",
      "React.js",
      "Git",
      "GitHub",
      "AngularJS",
      "JSON",
    ],
    companyLogo:
      "https://images.credly.com/size/340x340/images/ae2f5bae-b110-4ea1-8e26-77cf5f76c81e/GCC_badge_IT_Support_1000x1000.png",
  },
  {
    id: 4,
    company: "L&T Technology Services",
    location: "Bengaluru, Karnataka, India",
    isWorkplace: true,
    designation: "Software Engineer",
    dateJoining: "Jan 2021",
    dateEnd: "Mar 2023",
    skills: [
      "AngularJS",
      "Material UI",
      "PrimeNG",
      "BootStrap",
      "JavaScript",
      "Docker Products",
      "Nginx",
      "HTML",
      "Cascading Style Sheets (CSS)",
      "Git",
      "GitHub",
    ],
    companyLogo:
      "https://images.credly.com/size/340x340/images/ae2f5bae-b110-4ea1-8e26-77cf5f76c81e/GCC_badge_IT_Support_1000x1000.png",
  },
  {
    id: 5,
    company: "Terralogic Inc.",
    location: "Bengaluru, Karnataka, India",
    isWorkplace: true,
    designation: "Software Engineer",
    dateJoining: "Mar 2023",
    dateEnd: "Jul 2023",
    skills: [
      "AngularJS",
      "React.js",
      "Material UI",
      "Tailwind CSS",
      "JavaScript",
      "Docker Products",
      "HTML",
      "Cascading Style Sheets (CSS)",
      "Git",
      "GitHub",
    ],
    companyLogo:
      "https://images.credly.com/size/340x340/images/ae2f5bae-b110-4ea1-8e26-77cf5f76c81e/GCC_badge_IT_Support_1000x1000.png",
  },
  {
    id: 6,
    company: "Walmart Inc.",
    location: "Bengaluru, Karnataka, India",
    isWorkplace: true,
    designation: "Software Engineer",
    dateJoining: "Aug 2023",
    dateEnd: "Present",
    skills: [
      "React.js",
      "Material UI",
      "JavaScript",
      "Typescript",
      "Docker",
      "HTML",
      "Cascading Style Sheets (CSS)",
      "Git",
      "GitHub",
      "Docusaurus",
    ],
    companyLogo:
      "https://images.credly.com/size/340x340/images/ae2f5bae-b110-4ea1-8e26-77cf5f76c81e/GCC_badge_IT_Support_1000x1000.png",
  },
];
